import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'SAFRA_MANEJO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				data_aplicacao: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_aplicacao)),
				area: FormatUtils.formatarValorTela(registro.area, 2)
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('safraManejoForm', {
			...registro,
			data_aplicacao: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_aplicacao)),
			area: FormatUtils.formatarValorTela(registro.area, 2)
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'SAFRA_MANEJO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_safra) {
    const request = axios.get(`${consts.API_URL}/safraManejo?id_safra=${id_safra || 0}`);
    return {
        type: 'SAFRA_MANEJO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/safraManejo`, {
				...registro,
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_safra));
				dispatch(setModoTela('cadastro', {
					...registro,
					...resp.data,
					area: FormatUtils.formatarValorBanco(registro.area, 2)
				}));
				dispatch(initForm({
					...registro,
					...resp.data,
					area: FormatUtils.formatarValorBanco(registro.area, 2)
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/safraManejo`, {
				...registro,
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_safra));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/safraManejo?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_safra));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/safraManejoTipo/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTipoProduto() {
    const request = axios.get(`${consts.API_URL}/produtoTipo/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_TIPO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaDoenca() {
    const request = axios.get(`${consts.API_URL}/doenca/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_DOENCA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPraga() {
    const request = axios.get(`${consts.API_URL}/praga/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_PRAGA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPlantaInvasora() {
    const request = axios.get(`${consts.API_URL}/plantaInvasora/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_PLANTA_INVASORA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidadeMedida() {
    const request = axios.get(`${consts.API_URL}/unidadeMedida/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_UNIDADE_MEDIDA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFormaAplicacao() {
    const request = axios.get(`${consts.API_URL}/formaAplicacao/listarSelect`);
    return {
        type: 'SAFRA_MANEJO_FORMA_APLICACAO_SELECT_LISTADO',
        payload: request
    };
}