import axios from 'axios';
import { showTabs, selectTab } from '../../../common/tab/tabActions';
import consts from '../../../consts';
import { setErroAPI, setSuccess } from '../../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../../common/dateFormat/DateFormat';
import FormatUtils from '../../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'SAFRA_MANEJO_INVASOR_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('safraManejoInvasorForm', {
			...registro
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'SAFRA_MANEJO_INVASOR_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_safra_manejo) {
    const request = axios.get(`${consts.API_URL}/safraManejoInvasor?id_safra_manejo=${id_safra_manejo || 0}`);
    return {
        type: 'SAFRA_MANEJO_INVASOR_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/safraManejoInvasor`, {
				...registro,
				dose: FormatUtils.formatarValorBanco(registro.dose, 4),
				custo: FormatUtils.formatarValorBanco(registro.custo, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_safra_manejo));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/safraManejoInvasor`, {
				...registro,
				dose: FormatUtils.formatarValorBanco(registro.dose, 4),
				custo: FormatUtils.formatarValorBanco(registro.custo, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_safra_manejo));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/safraManejoInvasor?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_safra_manejo));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
