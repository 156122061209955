import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import imagemSoLogo from '../assets/images/sologo.png';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';
import DateFormat from '../common/dateFormat/DateFormat';

import {
	getLista, getListaProdutor
} from './historicoAcessoActions';

class HistoricoAcesso extends Component {

	state = {
		linhaSelecionada: null,
		id_produtor: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaProdutor();
    }

    render() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Select
						name='id_produtor'
						label='Produtor' 
						placeholder='Informe o produtor'
						cols='12 12 12 12'
						options={this.props.listaProdutor}
						value={this.state.id_produtor}
						onChange={data => {
							this.setState({
								...this.state,
								id_produtor: data
							})
						}} />
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Último Acesso</Th>
								<Th alignCenter>Áreas</Th>
								<Th alignCenter>Analises</Th>
								<Th alignCenter>Safras</Th>
								<Th alignCenter>Manejos</Th>
								<Th alignCenter>Equipamentos</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => !this.state.id_produtor || item.id_pessoa == this.state.id_produtor).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_pessoa}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data)}</Td>
									<Td alignCenter>{item.quantidade_area}</Td>
									<Td alignCenter>{item.quantidade_analise}</Td>
									<Td alignCenter>{item.quantidade_safra}</Td>
									<Td alignCenter>{item.quantidade_manejo}</Td>
									<Td alignCenter>{item.quantidade_equipamento}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.historicoAcesso.modoTela,
	lista: state.historicoAcesso.lista,
	listaProdutor: state.historicoAcesso.listaProdutor,
	aguardando: state.historicoAcesso.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista, getListaProdutor
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HistoricoAcesso);
