import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Especie from '../especie/especie';
import PlantaInvasora from '../plantaInvasora/plantaInvasora';
import Praga from '../praga/praga';
import Doenca from '../doenca/doenca';
import ProdutoTipo from '../produtoTipo/produtoTipo';
import Produto from '../produto/produto';
import Pessoa from '../pessoa/pessoa';
import Safra from '../safra/safra';
import UnidadeMedida from '../unidadeMedida/unidadeMedida';
import SafraManejoTipo from '../SafraManejoTipo/safraManejoTipo';
import AnaliseSolo from '../analiseSolo/analiseSolo'
import HistoricoAcesso from '../historicoAcesso/historicoAcesso';
import ManejoRelatorio from '../manejoRelatorio/manejoRelatorio';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/especie' component={Especie} />
			<Route path='/plantaInvasora' component={PlantaInvasora} />
			<Route path='/praga' component={Praga} />
			<Route path='/doenca' component={Doenca} />
			<Route path='/unidadeMedida' component={UnidadeMedida} />
			<Route path='/safraManejoTipo' component={SafraManejoTipo} />
			<Route path='/produtoTipo' component={ProdutoTipo} />
			<Route path='/produto' component={Produto} />
			<Route path='/pessoa' component={Pessoa} />
			<Route path='/safra' component={Safra} />
			<Route path='/analiseSolo' component={AnaliseSolo} />
			<Route path='/historicoAcesso' component={HistoricoAcesso} />
			<Route path='/manejoRelatorio' component={ManejoRelatorio} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
