const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaUnidadeMedida: [{  id: 15, valor: 'Quilograma (kg)' }, { id: 16, valor: 'Metros Cúbicos (m³)' }],
	listaEquipamentoTipo: [
		{ id: 1, valor: 'Trator' }, { id: 2, valor: 'Colheitadeira' }, { id: 3, valor: 'Plantadeira' },
		{ id: 4, valor: 'Pulverizador' }, { id: 5, valor: 'Distribuidor' }, { id: 6, valor: 'Outro' }
	],
	listaPulverizadorTipo: [
		{ id: 1, valor: 'Acoplado' }, { id: 2, valor: 'Arrasto' }, { id: 3, valor: 'Automotriz ou Autopropelido' }, { id: 4, valor: 'Aéreo' }
	]
};
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'EQUIPAMENTO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'EQUIPAMENTO_LISTADO':
			return {
				...state,
				lista: action.payload.data
			};

		case 'EQUIPAMENTO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		default:
			return state;
	}
}
