import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

import {
	setModoTela, initForm
} from './analiseSoloActions';

class AnaliseSoloForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<>

					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardBody>
								<Row>
									<Field
										name='id_produtor'
										component={Select}
										label='Produtor' placeholder='Informe o produtor'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaProdutor} />

									<Field
										name='id_periodo'
										component={Select}
										label='Período' placeholder='Informe o período'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaPeriodo} />

									<Field
										name='matricula'
										component={LabelAndInput}
										label='Matrícula' placeholder='Informe a matrícula'
										cols='12 12 12 12'
										readOnly={readOnly}
										 />


									<Field
										name='id_area'
										component={Select}
										label='Talhão' placeholder='Informe o talhão'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaArea.filter(item => item.id_produtor == this.props.formularioValues.id_produtor)} />


							        <Field
										name='percentual_argila'
										component={LabelAndInput}
										label='Argila (%)' placeholder='Informe a argila'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='ph'
										component={LabelAndInput}
										label='pH' placeholder='Informe o pH'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='indice_smp'
										component={LabelAndInput}
										label='Índice SMP' placeholder='Informe o índice SMP'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='fosforo'
										component={LabelAndInput}
										label='Fósforo (mg dm3)' placeholder='Informe o fósforo'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='potassio'
										component={LabelAndInput}
										label='Potássio (mg dm3)' placeholder='Informe o potássio'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='percentual_materia_organica'
										component={LabelAndInput}
										label='Matéria Orgânica (%) ' placeholder='Informe o potássio'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='aluminio'
										component={LabelAndInput}
										label='Alumínio (cmolc dm3) ' placeholder='Informe o alumínio'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='calcio'
										component={LabelAndInput}
										label='Cálcio (cmolc dm3) ' placeholder='Informe o cálcio'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='magnesio'
										component={LabelAndInput}
										label='Magnésio (cmolc dm3) ' placeholder='Informe o magnésio'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='h_al'
										component={LabelAndInput}
										label='H + Al (cmolc dm3) ' placeholder='Informe o H + Al'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='ctc_ph'
										component={LabelAndInput}
										label='CTC pH 7,0 (cmolc dm3) ' placeholder='Informe o CTC pH'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='ctc_efetiva'
										component={LabelAndInput}
										label='CTC efetiva  (cmolc dm3) ' placeholder='Informe o CTC efetiva'
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='saturacao_ctc_ph_bases'
										component={LabelAndInput}
										label='Saturação CTC pH 7,0 por bases  (%) ' placeholder='Informe a saturação CTCpH 7,0 por bases '
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='saturacao_ctc_efetiva_aluminio'
										component={LabelAndInput}
										label='Saturação CTC efetiva por aluminio  (%) ' placeholder='Informe a saturação CTC efetiva por aluminio '
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='cobre'
										component={LabelAndInput}
										label='Cobre  (mg dm3) ' placeholder='Informe o cobre '
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='zinco'
										component={LabelAndInput}
										label='Zinco  (mg dm3) ' placeholder='Informe o zinco '
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='manganes'
										component={LabelAndInput}
										label='Manganês  (mg dm3) ' placeholder='Informe o manganês '
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='enxofre'
										component={LabelAndInput}
										label='Enxofre  (mg dm3) ' placeholder='Informe o enxofre '
										cols='12 6 4 4'
										readOnly={readOnly} />

                                    <Field
										name='sodio'
										component={LabelAndInput}
										label='Sódio  (mg dm3) ' placeholder='Informe o sódio '
										cols='12 6 4 4'
										readOnly={readOnly} />

									<Field
										name='bario'
										component={LabelAndInput}
										label='Bário ' placeholder='Informe o bário '
										cols='12 6 4 4'
										readOnly={readOnly} />

									<Field
										name='titanio'
										component={LabelAndInput}
										label='Titânio ' placeholder='Informe o titânio '
										cols='12 6 4 4'
										readOnly={readOnly} />

									<Field
										name='cromo'
										component={LabelAndInput}
										label='Cromo ' placeholder='Informe o cromo '
										cols='12 6 4 4'
										readOnly={readOnly} />

									<Field
										name='cobalto'
										component={LabelAndInput}
										label='Cobalto ' placeholder='Informe o cobalto '
										cols='12 6 4 4'
										readOnly={readOnly} />

									<Field
										name='ferro'
										component={LabelAndInput}
										label='Ferro ' placeholder='Informe o ferro '
										cols='12 6 4 4'
										readOnly={readOnly} />

									</Row>
									<Row>

									<LabelAndInputDate
										name='data_coleta'
										label='Data da Coleta' placeholder='Informe o data'
										cols='12 6 4 4'
										readOnly={readOnly}
										value={this.props.formularioValues.data_coleta}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												data_coleta: data.target.value
											});
										}} />

									<LabelAndInputDate
										name='data_recebimento'
										label='Data de Recebimento' placeholder='Informe o data de recebimento'
										cols='12 6 4 4'
										readOnly={readOnly}
										value={this.props.formularioValues.data_recebimento}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												data_recebimento: data.target.value
											});
										}} />

									<LabelAndInputDate
										name='data_expedicao'
										label='Data de Expedição' placeholder='Informe o data de expedição'
										cols='12 6 4 4'
										readOnly={readOnly}
										value={this.props.formularioValues.data_expedicao}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												data_expedicao: data.target.value
											});
										}} />

									</Row>
							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='6 6 4 3'>
										{this.props.excluir ? (
											<Button
												text='Excluir'
												submit type={'danger'}
												icon={'fa fa-trash'} />
										) : (
										<Button
											text='Salvar'
											submit
											disabled={this.props.colaborador || this.props.area}
											type={'success'}
											icon={'fa fa-check'} />
										)}
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</Form>
					</ContentCard>
				{/* )} */}
			</>
        )
    }


}

AnaliseSoloForm = reduxForm({form: 'analiseSoloForm', destroyOnUnmount: false})(AnaliseSoloForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('analiseSoloForm')(state),
	registro: state.analiseSolo.registro,
	listaProdutor: state.analiseSolo.listaProdutor,
	listaArea: state.analiseSolo.listaArea,
	listaPeriodo: state.analiseSolo.listaPeriodo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AnaliseSoloForm);
