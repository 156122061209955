import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';

import imagemUser from '../assets/images/user.jpg';

import {  } from './dashboardActions';

class Dashboard extends Component {

	state = {

	}

	constructor(props) {
		super(props);
    }

    componentWillMount() {

    }

    render() {

        return (
			<Content>
			</Content>
        )
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
