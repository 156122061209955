const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaEstado: [],
	listaCidade: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'AREA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'AREA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'AREA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'AREA_ESTADO_SELECT_LISTADO':
			return {
				...state,
				listaEstado: action.payload.data
			};

		case 'AREA_CIDADE_SELECT_LISTADO':
			return {
				...state,
				listaCidade: action.payload.data
			};

        default:
            return state;
    }
}
