const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaProdutor: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'HISTORICO_ACESSO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'HISTORICO_ACESSO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'HISTORICO_ACESSO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'HISTORICO_ACESSO_PRODUTOR_SELECT_LISTADO':
			return {
				...state,
				listaProdutor: action.payload.data
			};

        default:
            return state;
    }
}
