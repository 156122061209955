import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import Colaborador from './colaborador/colaborador';
import Area from './area/area';
import Galpao from './galpao/galpao'
import Equipamento from './equipamento/equipamento'
import {
	setModoTela, initForm, imprimir
} from './pessoaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Td from '../common/table/td';
import TBody from '../common/table/tBody';
import Th from '../common/table/th';


class PessoaImprimirForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.colaborador || this.props.galpao || this.props.area ? 'readOnly' : '';

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 12 12'
									readOnly={'readOnly'} />								
							</Row>

							<Row>
								<div class='icheck-success d-inline'
									style={{ padding: 8 }}>
									<input type='checkbox' 
										id='mostrar-custos'
										checked={(this.props.formularioValues || {}).mostrarCustos}
										onChange={() => {
											this.props.initForm({
												...this.props.formularioValues,
												mostrarCustos: !this.props.formularioValues.mostrarCustos
											})
										}} />
									<label for='mostrar-custos'>
										Mostrar Custos
									</label>
								</div>
							</Row>

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Tipos de Manejo</label>
								</Grid>
								{(this.props.formularioValues ? this.props.formularioValues.tipoManejos || [] : []).map(tipoManejo => {

									return (
										<div class='icheck-success d-inline'
											style={{ padding: 8 }}>
											<input type='checkbox' 
												id={tipoManejo.id}
												checked={tipoManejo.selecionado}
												onChange={() => {
													this.props.initForm({
														...this.props.formularioValues,
														tipoManejos: this.props.formularioValues.tipoManejos.map(item => ({
															...item,
															selecionado: item.id == tipoManejo.id ? !item.selecionado : item.selecionado
														}))
													})
												}} />
											<label for={tipoManejo.id}>
												{tipoManejo.nome}
											</label>
										</div>
									);
								})}
							</Row>

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Áreas / Talhão</label>
								</Grid>

								<Table responsive>
									<THead>
										<Tr>
											<Th alignCenter>Imprimir</Th>
											<Th alignCenter>Produtor</Th>
											<Th alignCenter>Talhão</Th>
											<Th alignCenter>Cultura</Th>
											<Th alignCenter>Área</Th>
											<Th alignCenter>Data da Semeadura</Th>
											<Th alignCenter>Data da Colheita</Th>
										</Tr>
									</THead>
									<TBody>
										{(this.props.formularioValues ? this.props.formularioValues.safrasEncontradas || [] : []).map(safra => {

											return (

												<Tr key={safra.id}>
													<Td alignCenter>
														<div class='icheck-success d-inline'
															style={{ padding: 8 }}>
															<input type='checkbox' 
																id={safra.id}
																checked={safra.selecionado}
																onChange={() => {
																	this.props.initForm({
																		...this.props.formularioValues,
																		safrasEncontradas: this.props.formularioValues.safrasEncontradas.map(item => ({
																			...item,
																			selecionado: item.id == safra.id ? !item.selecionado : item.selecionado
																		}))
																	})
																}} />
															<label for={safra.id}>
																
															</label>
														</div>
													</Td>
													<Td alignCenter>{safra.nome_produtor}</Td>
													<Td alignCenter>{safra.nome_area}</Td>
													<Td alignCenter>{safra.nome_especie}</Td>
													<Td alignCenter>{safra.area}</Td>
													<Td alignCenter>{safra.data_semeadura}</Td>
													<Td alignCenter>{safra.data_colheita}</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Row>
						</ContentCardBody>
						
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									<Button
										text='Imprimir'
										type={'primary'}
										icon={'fa fa-check'}
										event={() => this.imprimir()} />
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
        )
    }

	imprimir() {

		if (this.props.formularioValues) {

			let registros = (this.props.formularioValues.safrasEncontradas || []).filter(item => item.selecionado).map(safra => ({
				...safra,
				manejos: safra.manejos.filter(manejo => {
					return (manejo.id_tipos || []).filter(idTipo => (this.props.formularioValues.tipoManejos || []).filter(tipo => tipo.selecionado && tipo.id == idTipo).length).length;
				})
			}));

			this.props.imprimir({
				registros: registros,
				mostrarCustos: this.props.formularioValues.mostrarCustos
			});
		}
	}

}

PessoaImprimirForm = reduxForm({form: 'pessoaForm', destroyOnUnmount: false})(PessoaImprimirForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('pessoaForm')(state),
	registro: state.pessoa.registro,
	listaEspecie: state.pessoa.listaEspecie,
	listaEstado: state.pessoa.listaEstado,
	listaCidade: state.pessoa.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, imprimir
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PessoaImprimirForm);
