import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import Colaborador from './colaborador/colaborador';
import Area from './area/area';
import Galpao from './galpao/galpao'
import Equipamento from './equipamento/equipamento'
import {
	setModoTela, initForm
} from './pessoaActions';

class PessoaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.colaborador || this.props.galpao || this.props.area ? 'readOnly' : '';

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						{!this.props.gerarSenha ? (
							<ContentCardBody>
								<Row>
									<Field
										name='nome'
										component={LabelAndInput}
										label='Nome' placeholder='Informe o nome'
										cols='12 12 12 12'
										readOnly={readOnly} />

									{this.props.colaborador || this.props.galpao || this.props.area ? null : (<>

									<Field
										name='codigo'
										component={LabelAndInput}
										label='Código' placeholder='Informe o código'
										cols='12 4 4 4'
										readOnly={readOnly} />

									<Field
										name='cpf'
										component={LabelAndInputMask}
										label='CPF' placeholder='Informe o CPF'
										cols='12 4 4 4'
										mask='999.999.999-99'
										readOnly={readOnly} />
										<Field

										name='inscricao_estadual'
										component={LabelAndInput}
										label='Inscrição Estadual' placeholder='Informe a inscrição estadual'
										cols='12 4 4 4'
										readOnly={readOnly} />

									<Field
										name='id_estado'
										component={Select}
										label='Estado' placeholder='Informe o estado'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaEstado} />

									<Field
										name='id_cidade'
										component={Select}
										label='Município' placeholder='Informe o município'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado)} />

									<Field
										name='endereco'
										component={LabelAndInput}
										label='Endereço' placeholder='Informe o endereco'
										cols='12 12 12 12'
										readOnly={readOnly} />

									<Field
										name='telefone'
										component={LabelAndInputMask}
										label='Telefone' placeholder='Informe o telefone'
										cols='12 6 6 6'
										mask='(99) 9 9999-9999'
										readOnly={readOnly} />

									<Field
										name='email'
										component={LabelAndInput}
										label='E-mail' placeholder='Informe o email'
										cols='12 6 6 6'
										readOnly={readOnly} />
									
									</>)}

									<Field
										name='area'
										component={LabelAndInputNumber}
										label='Área (hectares)' placeholder='Informe a área total'
										cols='12 12 12 12'
										casas={2}
										readOnly={readOnly} />

								</Row>
							</ContentCardBody>
						) : (
							<ContentCardBody>
								<Row>
									<Field
										name='senha'
										component={LabelAndInput}
										label='Senha' placeholder='Informe a senha'
										cols='12 12 12 12'
										readOnly={readOnly} />
								</Row>
							</ContentCardBody>
						)}
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										disabled={this.props.colaborador || this.props.area || this.props.galpao}
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.colaborador ?
					<Colaborador />
				: null}
				{this.props.galpao ?
					<Galpao />
				: null}

				{this.props.equipamento ?
					<Equipamento />
				: null}

				{this.props.area ?
					<Area />
				: null}
			</>
        )
    }


}

PessoaForm = reduxForm({form: 'pessoaForm', destroyOnUnmount: false})(PessoaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('pessoaForm')(state),
	registro: state.pessoa.registro,
	listaEspecie: state.pessoa.listaEspecie,
	listaEstado: state.pessoa.listaEstado,
	listaCidade: state.pessoa.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PessoaForm);
