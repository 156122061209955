import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm
} from './especieActions';

class EspecieForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorImagem = document.createElement('input');
		fileSelectorImagem.setAttribute('type', 'file');
		fileSelectorImagem.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.imagem || []).map(item => item);
					lista.push({
						imagem_base64: reader.result
					});

					let imagemFonte = (this.props.formularioValues.imagem_fonte || []).map(item => item);
					imagemFonte.push('');

					this.props.initForm({
						...this.props.formularioValues,
						imagem: lista,
						imagem_fonte: imagemFonte
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorImagem = fileSelectorImagem;

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome comum' placeholder='Informe o nome comum'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='nome_cientifico'
								component={LabelAndInput}
								label='Nome científico' placeholder='Informe o nome científico'
								cols='12 12 12 12'
								italic
								readOnly={readOnly} />

							<Field
								name='familia'
								component={LabelAndInput}
								label='Família' placeholder='Informe a família'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Grid cols='12 12 12 12'>
								<label>Imagem</label>
							</Grid>
							{(this.props.formularioValues.imagem || []).map((arquivo, i) => (
								<div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
									<span class="btn btn-outline-primary fileinput-button dz-clickable">
										<img src={arquivo.imagem_base64 ? arquivo.imagem_base64 : (arquivo.imagem ? `https://api-cisbra.infinitum-ti.com/${arquivo.imagem}` : imagemAdd)} style={{ height: 400 }} />
									</span>
									<div class='form-group' style={{ padding: 0, marginTop: 4, marginBottom: 0 }}>
										<input class='form-control'
											placeholder='Informe a fonte'
											value={(this.props.formularioValues.imagem_fonte || [])[i]}
											onChange={(data) => {

												let imagemFontes = this.props.formularioValues.imagem_fonte || [];

												if (imagemFontes.length != this.props.formularioValues.imagem.length) {
													imagemFontes = this.props.formularioValues.imagem.map(imagem => '');
												}

												imagemFontes = imagemFontes.map((fonte, j) => {
													if (i == j) {
														return data.target.value;
													} else {
														return fonte;
													}
												})

												this.props.initForm({
													...this.props.formularioValues,
													imagem_fonte: imagemFontes
												});
											}} />
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
										<button
											type='button'
											class='btn btn-flat btn-outline-primary'
											style={{ width: '48%' }}
											disabled={arquivo.imagem_base64 ?true : false}
											onClick={() => {
												window.open(`https://api-cisbra.infinitum-ti.com/${arquivo.imagem}`, '_blank');
											}}>
											<i class='fas fa-download'></i>
										</button>

										<button
											type='button'
											class='btn btn-flat btn-outline-danger'
											style={{ width: '48%' }}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													imagem: this.props.formularioValues.imagem.filter(item => item != arquivo),
													imagem_fonte: this.props.formularioValues.imagem_fonte.filter((item, j) => i != j)
												});
											}}>
											<i class='fas fa-trash-alt'></i>
										</button>
									</div>
								</div>
							))}

							<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorImagem.click()}>
								<img src={imagemAdd} style={{ height: 400 }} />
							</span>
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

EspecieForm = reduxForm({form: 'especieForm', destroyOnUnmount: false})(EspecieForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('especieForm')(state),
	registro: state.especie.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EspecieForm);
