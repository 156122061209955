import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../../common/template/content';
import ContentCard from '../../../common/template/contentCard';
import ContentCardHeader from '../../../common/template/contentCardHeader';
import ContentCardBody from '../../../common/template/contentCardBody';
import ContentCardFooter from '../../../common/template/contentCardFooter';
import Row from '../../../common/layout/row';
import Grid from '../../../common/layout/grid';
import Button from '../../../common/button/button';
import Form from '../../../common/form/form';
import LabelAndInput from '../../../common/form/labelAndInput';
import LabelAndInputNumber from '../../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../../common/form/labelAndInputDateTime';
import LabelAndInputDate from '../../../common/form/labelAndInputDate';
import Select from '../../../common/form/select';
import SelectAlteracao from '../../../common/form/selectAlteracao';
import { setError } from '../../../common/toast/toast';
import FormatUtils from '../../../common/formatUtils/FormatUtils';
import DateFormat from '../../../common/dateFormat/DateFormat';

import {
	setModoTela, initForm
} from './safraManejoProdutoActions';
import labelAndInput from '../../../common/form/labelAndInput';

class SafraManejoProdutoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

					<Field
							name='id_produto_tipo'
							component={Select}
							label='Tipo de produto' placeholder='Informe o tipo de produto'
							cols='12 12 12 12'
							readOnly={readOnly}
							options={this.props.listaTipoProduto} />
                  
						{this.props.formularioValues.id_produto_tipo == '30' 
						|| this.props.formularioValues.id_produto_tipo == '29'
						|| this.props.formularioValues.id_produto_tipo == '28'
						|| this.props.formularioValues.id_produto_tipo =='27' ?
						
						<Field
							name='formula'
							component={labelAndInput}
							label='Fórmula' placeholder='Informe a fórmula'
							cols='12 12 12 12'
							readOnly={readOnly}/>
							:
						<Field
							name='id_produto'
							component={Select}
							label='Produto' placeholder='Informe o produto'
							cols='12 12 12 12'
							readOnly={readOnly}
							options={this.props.listaProduto.filter(item => item.produto_tipos.includes(this.props.formularioValues.id_produto_tipo))} 
							/>
					}
						<Field
							name='id_unidade_medida'
							component={Select}
							label='Unidade de Medida' placeholder='Informe a unidade de medida'
							cols='12 12 12 12'
							readOnly={readOnly}
							options={this.props.listaUnidadeMedida} />

						<Field
							name='dose'
							component={LabelAndInputNumber}
							label='Dose por hectare' placeholder='Informe a dose'
							cols='12 6 4 4'
							casas={4}
							readOnly={readOnly} />

						<Field
							name='custo'
							component={LabelAndInputNumber}
							label='Custo por hectare (R$)' placeholder='Informe o custo'
							cols='12 6 4 4'
							casas={2}
							readOnly={readOnly} />
								
						{this.props.formularioValues.id_produto_tipo == '30' 
						|| this.props.formularioValues.id_produto_tipo == '29'
						|| this.props.formularioValues.id_produto_tipo == '28'
						|| this.props.formularioValues.id_produto_tipo =='27' ?
						
									<>


										<Field
											name='id_forma_aplicacao'
											component={Select}
											label='Forma de Aplicação' placeholder='Informe a forma de aplicação'
											cols='12 6 4 4'
											casas={0}
											readOnly={readOnly}
											options={this.props.listaFormaAplicacao} />

									</>
									: null}
						

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

SafraManejoProdutoForm = reduxForm({form: 'safraManejoProdutoForm', destroyOnUnmount: false})(SafraManejoProdutoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('safraManejoProdutoForm')(state),
	registro: state.safraManejoProduto.registro,
	listaTipoProduto: state.safraManejo.listaTipoProduto,
	listaProduto: state.safraManejo.listaProduto,
	listaDoenca: state.safraManejo.listaDoenca,
	listaPraga: state.safraManejo.listaPraga,
	listaPlantaInvasora: state.safraManejo.listaPlantaInvasora,
	listaUnidadeMedida: state.safraManejo.listaUnidadeMedida,
	listaFormaAplicacao: state.safraManejo.listaFormaAplicacao,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SafraManejoProdutoForm);
