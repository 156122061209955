const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaEspecie: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PRAGA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PRAGA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'PRAGA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'PRAGA_ESPECIE_SELECT_LISTADO':
			return {
				...state,
				listaEspecie: action.payload.data
			};

        default:
            return state;
    }
}
