import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';

import {
	setModoTela, initForm
} from './safraManejoTipoActions';

class SafraManejoTipoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

                        <Row>
							<Field
								name='doenca'
								component={Select}
								label='Doenças' placeholder='Informe a doença'
								cols='12 12 12 12'
								readOnly={readOnly}
                                options={this.props.listaDoencas} />
						</Row>

                        <Row>
							<Field
								name='praga'
								component={Select}
								label='Inseto-Praga' placeholder='Informe o inseto-praga'
								cols='12 12 12 12'
								readOnly={readOnly}
                                options={this.props.listaPragas} />
						</Row>

                        <Row>
							<Field
								name='planta_invasora'
								component={Select}
								label='Planta Invasora' placeholder='Informe a planta invasora'
								cols='12 12 12 12'
								readOnly={readOnly} 
                                options={this.props.listaPlantaInvasora}/>
						</Row>
					{console.log(this.props.formularioValues)}
						<Select
								options={this.props.listaProdutoTipo}
								label='Tipos de Produto'
								cols='12 12 12 12'
								placeholder='Selecione o produto'
								readOnly={readOnly}
								multiple={true}
								value={this.props.listaProdutoTipo.map(item => ({ value: item.id, label: item.valor }))
								.filter(item => (this.props.formularioValues.produto_tipos || []).includes(item.value))}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										produto_tipos: value.map(item => item.value)
									});
								}} />
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

SafraManejoTipoForm = reduxForm({form: 'safraManejoTipoForm', destroyOnUnmount: false})(SafraManejoTipoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('safraManejoTipoForm')(state),
	registro: state.safraManejoTipo.registro,
    listaDoencas: state.safraManejoTipo.listaDoencas,
    listaPragas: state.safraManejoTipo.listaPragas,
    listaPlantaInvasora: state.safraManejoTipo.listaPlantaInvasora,
	listaProdutoTipo: state.safraManejoTipo.listaProdutoTipo,
	lista: state.safraManejoTipo.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SafraManejoTipoForm);
