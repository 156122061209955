import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PESSOA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				area: FormatUtils.formatarValorTela(registro.area, 2)
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('pessoaForm', {
			...registro,
			area: FormatUtils.formatarValorTela(registro.area, 2)
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'PESSOA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/pessoa?id_pessoa_tipo=1`);
    return {
        type: 'PESSOA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/pessoa`, {
				...registro,
				cpf: FormatUtils.removerMascara(registro.cpf),
				telefone: FormatUtils.removerMascara(registro.telefone),
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/pessoa`, {
				...registro,
				cpf: FormatUtils.removerMascara(registro.cpf),
				telefone: FormatUtils.removerMascara(registro.telefone),
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function salvarSenha(registro) {
    return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/pessoa/alterarSenha`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/pessoa?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarSelect`);
    return {
        type: 'PESSOA_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'PESSOA_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function initFormImprimir(produtor) {

	return async (dispatch, getState) => {

		let listaArea = [];
		await axios.get(`${consts.API_URL}/area?id_produtor=${produtor.id}`).then(result => {
			listaArea = result.data.filter(item => item.id_produtor == produtor.id);
		}).catch(ex => {
			console.log(ex);
		});

		let listaTipoManejo = [];
		await axios.get(`${consts.API_URL}/safraManejoTipo/listarSelect`).then(result => {
			listaTipoManejo = result.data;
		}).catch(ex => {
			console.log(ex);
		});

		let listaAreasImprimir = [];

		let manejosEncontrados = [];

		let safrasEncontradas = [];

		for (let g = 0; g < listaArea.length; g++) {
			const area = listaArea[g];

			let registroArea = {
				id: area.id,
				nome: area.nome,
				safras: []
			};
			
			let listaSafra = [];
			await axios.get(`${consts.API_URL}/safra?id_area=${area.id}`).then(async result => {
				listaSafra = result.data.filter(item => item.id_area == area.id);
			}).catch(ex => {
				console.log(ex);
			});

			for (let h = 0; h < listaSafra.length; h++) {
				const safra = listaSafra[h];
		
				let registroSafra = {
					id: safra.id,
					nome_produtor: safra.nome_produtor,
					nome_area: safra.nome_area,
					nome_especie: safra.nome_especie,
					area: `${FormatUtils.formatarValorTela(safra.area || 0, 2)} - hectares`,
					data_semeadura: DateFormat.formatarDataSqlParaTela(safra.data_semeadura),
					data_colheita: DateFormat.formatarDataSqlParaTela(safra.data_colheita),
					produtividade: `${FormatUtils.formatarValorTela(safra.produtividade || 0, 2)}`,
					manejos: [],
					selecionado: true
				}
				
				let listaManejo = []
				await axios.get(`${consts.API_URL}/safraManejo?id_safra=${safra.id}`).then(result => {
					listaManejo = result.data;
				}).catch(ex => {
					console.log(ex);
				});
				
				for (let i = 0; i < listaManejo.length; i++) {
					const manejo = listaManejo[i];
					let registroManejo = {
						id: manejo.id,
						area: `${FormatUtils.formatarValorTela(manejo.area || 0, 2)} - hectares`,
						data_aplicacao: DateFormat.formatarDataSqlParaTela(manejo.data_aplicacao),
						descricao: manejo.descricao,
						id_tipos: manejo.id_tipos,
						descricao_tipos: manejo.descricao_tipos,
						densidade: `${FormatUtils.formatarValorTela(manejo.densidade || 0, 0)}`,
						espacamento: `${FormatUtils.formatarValorTela(manejo.espacamento || 0, 2)} cm`,
						populacao: `${FormatUtils.formatarValorTela(manejo.populacao || 0, 0)} ${manejo.unidade_medida_populacao || ''}`,
						produtos: [],
						invasores: []
					}

					for (let im = 0; im < (manejo.id_tipos || []).length; im++) {
						const idTipo = (manejo.id_tipos || [])[im];
						
						if (idTipo && !manejosEncontrados.filter(tipo => tipo.id == idTipo)[0]) {
							let tipoManejo = listaTipoManejo.filter(tipo => tipo.id == idTipo)[0];
							if (tipoManejo) {
								manejosEncontrados.push({ id: tipoManejo.id, nome: tipoManejo.valor, selecionado: true });
							}
						}
					}

					let listaProdutos = [];
					await axios.get(`${consts.API_URL}/safraManejoProduto?id_safra_manejo=${manejo.id}`).then(result => {
						listaProdutos = result.data;
					}).catch(ex => {
						console.log(ex);
					});

					for (let j = 0; j < listaProdutos.length; j++) {
						const produto = listaProdutos[j];
						
						registroManejo.produtos.push({
							id: produto.id,
							custo: `R$ ${FormatUtils.formatarValorTela(produto.custo || 0, 2)} por hectare`,
							dose: `${FormatUtils.formatarValorTela(produto.dose || 0, 3)} ${produto.nome_unidade_medida || ''}`,
							formula: produto.formula,
							nome_produto: produto.nome_produto,
							nome_produto_tipo: produto.nome_produto_tipo,
							nome_tipo: produto.nome_tipo
						});
					}

					let listaInvasores = []
					await axios.get(`${consts.API_URL}/safraManejoInvasor?id_safra_manejo=${manejo.id}`).then(result => {
						listaInvasores = result.data;
					}).catch(ex => {
						console.log(ex);
					});

					for (let j = 0; j < listaInvasores.length; j++) {
						const invasor = listaInvasores[j];
						
						registroManejo.invasores.push({
							id: invasor.id,
							tipo: invasor.nome_doenca ? 'Doença' : (invasor.nome_planta_invasora ? 'Planta Invasora' : 'Praga'),
							nome: invasor.nome_doenca || invasor.nome_planta_invasora || invasor.nome_praga
						});
					}

					registroSafra.manejos.push(registroManejo);
						
				}

				registroArea.safras.push(registroSafra);

				safrasEncontradas.push(registroSafra);
				
			}

			listaAreasImprimir.push(registroArea);
			
		}

		


		dispatch(initForm({
			...produtor,
			areas: listaAreasImprimir,
			safrasEncontradas: safrasEncontradas,
			tipoManejos: manejosEncontrados,
			mostrarCustos: true
		}));

	}
}

export function imprimir(registro) {

	return async (dispatch, getState) => {

		axios.post(`${consts.API_URL}/relatorioSafra/gerarPdf`, registro).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}