const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaEstrutura: [],
	listaUnidadeMedida:[],
	


};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'GALPAO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'GALPAO_LISTADO':
			return {
				...state,
				lista: action.payload.data
			};

		case 'GALPAO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'GALPAO_ESTRUTURA_SELECT_LISTADO':
			return {
				...state,
				listaEstrutura: action.payload.data
			};
		case 'GALPAO_UNIDADE_MEDIDA_SELECT_LISTADO':
			console.log(action.payload.data);
			return {
				...state,
				listaUnidadeMedida: action.payload.data.filter(item => item.id == 9 || item.id == 14 )
			};

		default:
			return state;
	}
}
