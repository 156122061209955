import React, { Component } from 'react';
import MenuItem from './menuItem';
import MenuTree from './menuTree';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Menu extends Component {

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
			        <MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />
					<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
					<MenuItem path='especie' label='Culturas' icon='fas fa-leaf' active={selecionado == '/especie'} />
					<MenuItem path='plantaInvasora' label='Plantas Invasoras' icon='fab fa-pagelines' active={selecionado == '/plantaInvasora'} />
					<MenuItem path='praga' label='Insetos-Praga' icon='fas fa-bug' active={selecionado == '/praga'} />
					<MenuItem path='doenca' label='Doenças' icon='fas fa-disease' active={selecionado == '/doenca'} />
					<MenuItem path='unidadeMedida' label='Unidade de Medida' icon='fas fa-balance-scale-right' active={selecionado == '/unidadeMedida'} />
					<MenuItem path='safraManejoTipo' label='Tipos de Manejos' icon='fas fa-tractor' active={selecionado == '/safraManejoTipo'} />
					<MenuItem path='produtoTipo' label='Tipos de Produtos' icon='fas fa-list' active={selecionado == '/produtoTipo'} />
					<MenuItem path='produto' label='Produtos' icon='fas fa-box' active={selecionado == '/produto'} />
					<MenuItem path='pessoa' label='Produtor' icon='fas fa-user-circle' active={selecionado == '/pessoa'} />
					<MenuItem path='analiseSolo' label='Análise de Solo' icon='fas fa-clipboard-list' active={selecionado == '/analiseSolo'} />
					<MenuItem path='historicoAcesso' label='Histórico de Acesso' icon='fas fa-clock' active={selecionado == '/historicoAcesso'} />					
					<MenuItem path='manejoRelatorio' label='Histórico por Tipos' icon='fas fa-bars' active={selecionado == '/manejoRelatorio'} />
					<MenuItem path='safra' label='Safras' icon='fas fa-seedling' active={selecionado == '/safra'} />		
		    	</ul>
			</nav>
		);

	}

}

const mapStateToProps = state => ({
	usuarioLogado: state.auth.usuarioLogado
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
