import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'GALPAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
			
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('galpaoForm', {
			...registro,
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'GALPAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_produtor) {
    const request = axios.get(`${consts.API_URL}/galpao?id_produtor=${id_produtor}`);
    return {
        type: 'GALPAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/galpao`, {
				...registro,
		
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_produtor));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/galpao`, {
				...registro,
			
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_produtor));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/galpao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_produtor));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


export function getListaUnidadeMedida() {
    const request = axios.get(`${consts.API_URL}/unidadeMedida/listarSelect`);
    return {
        type: 'GALPAO_UNIDADE_MEDIDA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEstrutura() {
    const request = axios.get(`${consts.API_URL}/estrutura/listarSelect`);
    return {
        type: 'GALPAO_ESTRUTURA_SELECT_LISTADO',
        payload: request
    };
}