import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pessoaForm';
import FormImprimir from './pessoaImprimirForm';
import imagemSoLogo from '../assets/images/sologo.png';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	setModoTela, initForm, salvar, salvarSenha, excluir, getLista, getListaEstado, getListaCidade, initFormImprimir
} from './pessoaActions';

class Pessoa extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getLista();
		this.props.getListaEstado();
		this.props.getListaCidade();
	}

	render() {
		return (
			<div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: '50%', marginRight: '50%' }}>
									<img src={imagemSoLogo} style={{ height: 40, width: 40, marginBottom: -76, marginLeft: 8 }} />
								</div>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'colaborador' ? (
						<Form colaborador />
					) : null}
					{this.props.modoTela == 'galpao' ? (
						<Form galpao />
					) : null}
					{this.props.modoTela == 'equipamento' ? (
						<Form equipamento />
					) : null}
					{this.props.modoTela == 'area' ? (
						<Form area />
					) : null}
					{this.props.modoTela == 'gerarSenha' ? (
						<Form gerarSenha onSubmit={this.props.salvarSenha} />
					) : null}
					{this.props.modoTela == 'pessoaImprimir' ? (
						<FormImprimir onSubmit={this.props.salvar} />
					) : null}
				</Content>
			</div>
		)
	}

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									//Produtor
									this.props.setModoTela('cadastro', { id_pessoa_tipo: 1 });
									this.props.initForm({ id_pessoa_tipo: 1 });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th>Código</Th>
								<Th>Endereço</Th>
								<Th>Telefone</Th>
								<Th>Estado</Th>
								<Th>Município</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td>{item.codigo}</Td>
									<Td>{item.endereco}</Td>
									<Td>{FormatUtils.formatarTelefone(item.telefone)}</Td>
									<Td>{item.nome_estado}</Td>
									<Td>{item.nome_cidade}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-pdf'}
											visible={this.state.linhaSelecionada == item.id}
											title='Imprimir'
											event={() => {
												this.props.setModoTela('pessoaImprimir', {
													...item
												});
												this.props.initFormImprimir({
													...item
												});
											}} />
										<ButtonTable
											type={'primary'}
											icon={'fas fa-tractor'}
											visible={this.state.linhaSelecionada == item.id}
											title='Equipamento'
											event={() => {
												this.props.setModoTela('equipamento', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
										<ButtonTable
											type={'primary'}
											icon={'fas fa-warehouse'}
											visible={this.state.linhaSelecionada == item.id}
											title='Galpao'
											event={() => {
												this.props.setModoTela('galpao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
										<ButtonTable
											type={'primary'}
											icon={'fas fa-users'}
											visible={this.state.linhaSelecionada == item.id}
											title='Colaboradores'
											event={() => {
												this.props.setModoTela('colaborador', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'success'}
											icon={'fas fa-map-marked-alt'}
											visible={this.state.linhaSelecionada == item.id}
											title='Áreas'
											event={() => {
												this.props.setModoTela('area', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-unlock'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('gerarSenha', {
													...item
												});
												this.props.initForm({
													...item,
													senha: (item.cpf || '').substring(0, 5)
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pessoa.modoTela,
	lista: state.pessoa.lista,
	aguardando: state.pessoa.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, salvarSenha, excluir, getLista, getListaEstado, getListaCidade, initFormImprimir
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Pessoa);
