import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import LabelAndInputDate from '../../common/form/labelAndInputDate';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import { setError } from '../../common/toast/toast';

import {
	setModoTela, initForm
} from './equipamentoActions';

class EquipamentoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_equipamento_tipo'
								component={Select}
								label='Tipo de Equipamento' placeholder='Informe o tipo de equipamento'
								cols='6 6 6 6'
								options={this.props.listaEquipamentoTipo}
								readOnly={readOnly} />

							{this.props.formularioValues.id_equipamento_tipo == 4 ? ( //PULVERIZADOR
								<Field
									name='id_pulverizador_tipo'
									component={Select}
									label='Tipo de Pulverizador' placeholder='Informe o tipo de pulverizador'
									cols='6 6 6 6'
									options={this.props.listaPulverizadorTipo}
									readOnly={readOnly} />
							) : null}
						</Row>
						<Row>
							<Field
								name='modelo'
								component={LabelAndInput}
								label='Modelo' placeholder='Informe o modelo'
								cols='12 6 4 4'
								readOnly={readOnly} />

							<Field
								name='ano'
								component={LabelAndInput}
								label='Ano' placeholder='Informe o ano'
								cols='12 6 4 4'
								readOnly={readOnly} />
						</Row>
						{this.props.formularioValues.id_equipamento_tipo == 1 || this.props.formularioValues.id_equipamento_tipo == 2
							|| (this.props.formularioValues.id_equipamento_tipo == 4 && this.props.formularioValues.id_pulverizador_tipo == 3) ? ( //TRATOR, COLHEITADEIRA e PULVERIZADOR AUTOPROPELIDO
							<Row>
								<Field
									name='potencia'
									component={LabelAndInputNumber}
									label='Potência (CV)' placeholder='Informe a potência'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<LabelAndInputDate
									name='data_troca_oleo'
									label='Data da Troca de Óleo' placeholder='Informe o data'
									cols='12 4 4 4'
									readOnly={readOnly}
									value={this.props.formularioValues.data_troca_oleo}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											data_troca_oleo: data.target.value
										});
									}} />

								<Field
									name='km_troca_oleo'
									component={LabelAndInputNumber}
									label='Odômetro' placeholder='Informe o odômetro'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='hr_troca_oleo'
									component={LabelAndInputNumber}
									label='Horímetro' placeholder='Informe o horímetro'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<LabelAndInputDate
									name='data_troca_oleo_proxima'
									label='Data da Próxima Troca de Óleo' placeholder='Informe o data'
									cols='12 4 4 4'
									readOnly={readOnly}
									value={this.props.formularioValues.data_troca_oleo_proxima}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											data_troca_oleo_proxima: data.target.value
										});
									}} />
							</Row>
						) : null}

						{this.props.formularioValues.id_equipamento_tipo == 2 ? ( //COLHEITADEIRA
							<Row>
								<Field
									name='colheitadeira_plataforma'
									component={LabelAndInputNumber}
									label='Tamanho da Plataforma (m)' placeholder='Informe o tamanho'
									cols='12 6 4 4'
									casas={2}
									readOnly={readOnly} />
							</Row>
						) : null}

						{this.props.formularioValues.id_equipamento_tipo == 3 ? ( //PLANTADEIRA
							<Row>
								<Field
									name='plantadeira_linhas'
									component={LabelAndInputNumber}
									label='Linhas' placeholder='Informe a quantidade'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='plantadeira_espacamento_verao'
									component={LabelAndInputNumber}
									label='Espaçamento entre linhas Verão (cm)' placeholder='Informe o espaçamento'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='plantadeira_espacamento_inverno'
									component={LabelAndInputNumber}
									label='Espaçamento entre linhas Inverno (cm)' placeholder='Informe o espaçamento'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />
							</Row>
						) : null}

						{this.props.formularioValues.id_equipamento_tipo == 4 ? ( //PULVERIZADOR
							<Row>
								<Field
									name='pulverizador_capacidade'
									component={LabelAndInputNumber}
									label='Capacidade (l)' placeholder='Informe a capacidade'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='pulverizador_barra'
									component={LabelAndInputNumber}
									label='Tamanho da barra (m)' placeholder='Informe o tamanho'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='pulverizador_distancia_bicos'
									component={LabelAndInputNumber}
									label='Distância entre bicos (cm)' placeholder='Informe a distância'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />
							</Row>
						) : null}

						{this.props.formularioValues.id_equipamento_tipo == 5 ? ( //DISTRIBUIDOR
							<Row>
								<Field
									name='distribuidor_capacidade'
									component={LabelAndInputNumber}
									label='Capacidade' placeholder='Informe a capacidade'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='id_unidade_distribuidor_capacidade'
									component={Select}
									label='Unidade de Medida Capacidade' placeholder='Informe a unidade de medida'
									cols='12 6 4 4'
									options={this.props.listaUnidadeMedida}
									readOnly={readOnly} />

								<Field
									name='distribuidor_largura'
									component={LabelAndInputNumber}
									label='Largura de trabalho (m)' placeholder='Informe a largura'
									cols='12 6 4 4'
									casas={0}
									readOnly={readOnly} />
							</Row>
						) : null}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}
}

EquipamentoForm = reduxForm({ form: 'equipamentoForm', destroyOnUnmount: false })(EquipamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('equipamentoForm')(state),
	registro: state.equipamento.registro,
	listaEstrutura: state.equipamento.listaEstrutura,
	listaUnidadeMedida: state.equipamento.listaUnidadeMedida,
	listaEquipamentoTipo: state.equipamento.listaEquipamentoTipo,
	listaPulverizadorTipo: state.equipamento.listaPulverizadorTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EquipamentoForm);
