import React from 'react';
import Menu from './menu';
import imagemLogo from '../../assets/images/sologo.png';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo">
		<a href="../../" className="brand-link navbar-light">
			<img src={imagemLogo} alt="Cisbra Logo" className="brand-image" style={{ width: 60, height: 30, marginLeft: 0 }} />
			<span className="brand-text font-weight-light">Cisbra Agroindustrial</span>
		</a>
		<div className="sidebar">
			<Menu />
		</div>
	</aside>
)
