import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import { setError } from '../../common/toast/toast';

import {
	setModoTela, initForm
} from './colaboradorActions';

class ColaboradorForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='cpf'
								component={LabelAndInputMask}
								label='CPF' placeholder='Informe o CPF'
								cols='12 6 4 4'
								mask='999.999.999-99'
								readOnly={readOnly} />

							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço' placeholder='Informe o endereco'
								cols='12 6 4 4'
								readOnly={readOnly} />

							<Field
								name='telefone'
								component={LabelAndInputMask}
								label='Telefone' placeholder='Informe o telefone'
								cols='12 6 4 4'
								mask='(99) 9 9999-9999'
								readOnly={readOnly} />
						</Row>
						<Row>

							<Field
								name='id_colaborador_regime'
								component={Select}
								label='Colaborador Regime' placeholder='Informe o coladorador'
								cols='12 6 6 6'
								options={this.props.listaColaboradorRegime}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='capacitados'
								component={Select}
								label='Os colaboradores são capacitados para a aplicação de agrotóxicos?'
								cols='12 6 6 6'
								options={this.props.listaCapacidade}
								readOnly={readOnly} />

						</Row>
						<Row>

							<Field
								name='epi'
								component={Select}
								label='Os colaboradores recebem Equipamento de Proteção Individual (EPI)?'
								cols='12 6 6 6'
								options={this.props.listaEpi}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='nr'
								component={Select}
								label='A propriedade segue a Norma Regulamentadora (NR) 31?'
								cols='12 6 6 6'
								options={this.props.listaNr}
								readOnly={readOnly} />

						</Row>




					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

ColaboradorForm = reduxForm({ form: 'colaboradorForm', destroyOnUnmount: false })(ColaboradorForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('colaboradorForm')(state),
	registro: state.colaborador.registro,
	listaColaboradorRegime: state.colaborador.listaColaboradorRegime,
	listaCapacidade: state.colaborador.listaCapacidade,
	listaEpi: state.colaborador.listaEpi,
	listaNr: state.colaborador.listaNr,
	listaEspecie: state.colaborador.listaEspecie,

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ColaboradorForm);
