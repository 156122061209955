import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import { setError } from '../../common/toast/toast';

import {
	setModoTela, initForm
} from './galpaoActions';

class GalpaoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_estrutura'
								component={Select}
								label='Estrutura' placeholder='Informe a estrutura'
								cols='12 12 12 12'
								options={this.props.listaEstrutura} 
								readOnly={readOnly} />

							<Field
								name='tamanho'
								component={LabelAndInputNumber}
								label='Tamanho' placeholder='Informe o tamanho'
								cols='12 6 4 4'
								readOnly={readOnly} />

							<Field
								name='id_unidade_medida'
								component={Select}
								label='Unidade de Medida' placeholder='Informe a unidade de medida'
								cols='12 6 4 4'
								options={this.props.listaUnidadeMedida} 
								readOnly={readOnly} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

GalpaoForm = reduxForm({ form: 'galpaoForm', destroyOnUnmount: false })(GalpaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('galpaoForm')(state),
	registro: state.galpao.registro,
	listaEstrutura: state.galpao.listaEstrutura,
	listaUnidadeMedida: state.galpao.listaUnidadeMedida,

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GalpaoForm);
