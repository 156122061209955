import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import Safra from '../../safra/safra';
import SelectAlteracao from '../../common/form/selectAlteracao';
import { setError } from '../../common/toast/toast';

import {
	setModoTela, initForm
} from './areaActions';

class AreaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.safra ? 'readOnly' : '';

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome do Talhão' placeholder='Informe o nome'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								{this.props.safra ? null : (<>

								<Field
									name='id_estado'
									component={Select}
									label='Estado' placeholder='Informe o estado'
									cols='12 12 12 12'
									readOnly={readOnly}
									options={this.props.listaEstado} />

								<Field
									name='id_cidade'
									component={Select}
									label='Município' placeholder='Informe o município'
									cols='12 12 12 12'
									readOnly={readOnly}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado)} />

								<Field
									name='matricula'
									component={LabelAndInputNumber}
									label='Número da Matrícula' placeholder='Informe a matrícula'
									cols='12 12 12 12'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='car'
									component={LabelAndInputNumber}
									label='CAR (Cadastro Ambiental Rural)' placeholder='Informe o CAR'
									cols='12 12 12 12'
									casas={0}
									readOnly={readOnly} />
								
								</>)}

								<Field
									name='area'
									component={LabelAndInputNumber}
									label='Área (hectares)' placeholder='Informe a área total'
									cols='12 12 12 12'
									casas={2}
									readOnly={readOnly} />
									
								{this.props.safra ? null : (<>

								<Field
									name='latitude'
									component={LabelAndInput}
									label='Latitude' placeholder='Informe a latitude'
									cols='12 6 6 6'
									readOnly={readOnly} />

								<Field
									name='longitude'
									component={LabelAndInput}
									label='Longitude' placeholder='Informe a longitude'
									cols='12 6 6 6'
									readOnly={readOnly} />

								<Field
									name='localizacao'
									component={LabelAndInput}
									label='Localização (link Maps)' placeholder='Informe a localizacao'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								</>)}

							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text={`${this.props.formularioValues.permanente ? 'Excluir Permanentemente' : 'Inativar'}`}
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.safra ? 
					<Safra idArea={this.props.formularioValues.id} /> 
				: null}

			</>
        )
    }


}

AreaForm = reduxForm({form: 'areaForm', destroyOnUnmount: false})(AreaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('areaForm')(state),
	registro: state.area.registro,
	listaEstado: state.area.listaEstado,
	listaCidade: state.area.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AreaForm);
