import React from 'react'
import Grid from '../layout/grid'

export default props => {
	return (
		<Grid cols={props.cols}>
			<div className='form-group'>
				<label htmlFor={props.name}>{props.label}</label>
				{props.onChange ? (
					<input {...props.input} className='form-control'
						type='date'
						max={props.max}
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						value={props.value ? props.value.split('/').join('-') : ''}
						onChange={props.onChange} />
				) : (
					<input {...props.input} className='form-control'
						type='date'
						placeholder={props.placeholder}
						readOnly={props.readOnly} />
				)}
			</div>
		</Grid>
	);
}
