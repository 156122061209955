import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import SafraManejo from './safraManejo/safraManejo';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

import {
	setModoTela, initForm
} from './safraActions';

class SafraForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<>
				{this.props.manejo ? (
					<>
						<ContentCard>
							<ContentCardBody>
								<Table responsive>
									<THead>
										<Tr>
											<Th>Produtor</Th>
											<Th>Talhão</Th>
											<Th>Cultura</Th>
											<Th alignRight>Área(hectares)</Th>
											<Th alignCenter>Semeadura</Th>
											<Th alignCenter>Colheita</Th>
											<Th></Th>
										</Tr>
									</THead>
									<TBody>
										<Tr>
											<Td>{this.props.registro.nome_produtor}</Td>
											<Td>{this.props.registro.nome_area}</Td>
											<Td>{this.props.registro.nome_especie}</Td>
											<Td alignRight>{this.props.registro.area} </Td>
											<Td alignCenter>{DateFormat.formatarDataSqlParaTela(this.props.registro.data_semeadura)}</Td>
											<Td alignCenter>{DateFormat.formatarDataSqlParaTela(this.props.registro.data_colheita)}</Td>
											<Td alignRight minWidth={100}>
												<ButtonTable
													type={'warning'}
													icon={'fas fa-arrow-left'}
													title='Voltar'
													visible={true}
													event={() => {
														this.props.setModoTela('lista', {});
														this.props.initForm({});
													}} />
											</Td>
										</Tr>
									</TBody>
								</Table>
							</ContentCardBody>
						</ContentCard>

						<SafraManejo />
					</>
				) : (
					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardBody>
								<Row>
									<Field
										name='id_produtor'
										component={Select}
										label='Produtor' placeholder='Informe o produtor'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaProdutor} />

									<Field
										name='id_area'
										component={Select}
										label='Talhão' placeholder='Informe o talhão'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaArea.filter(item => item.id_produtor == this.props.formularioValues.id_produtor)} />

									<Field
										name='id_especie_anterior'
										component={Select}
										label='Cultura antecessora' placeholder='Informe a cultura'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaEspecie} />

									<Field
										name='id_especie'
										component={Select}
										label='Cultura' placeholder='Informe a cultura'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaEspecie} />

									<LabelAndInputDate
										name='data_semeadura'
										label='Data da Semeadura' placeholder='Informe o data'
										cols='12 4 4 4'
										readOnly={readOnly}
										value={this.props.formularioValues.data_semeadura}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												data_semeadura: data.target.value
											});
										}} />

									<Field
										name='area'
										component={LabelAndInputNumber}
										label='Área (hectares)' placeholder='Informe a área'
										cols='12 6 4 4'
										casas={2}
										readOnly={readOnly} />


									<LabelAndInputDate
										name='data_colheita'
										label='Data da Colheita' placeholder='Informe o data'
										cols='12 6 4 4'
										readOnly={readOnly}
										value={this.props.formularioValues.data_colheita}
										onChange={data => {
											this.props.initForm({
												...this.props.formularioValues,
												data_colheita: data.target.value
											});
										}} />
										
									<Field
										name='produtividade'
										component={LabelAndInputNumber}
										label='Produtividade' placeholder='Informe a produtividade'
										cols='12 6 4 4'
										casas={1}
										readOnly={readOnly} />
										
									<Field
										name='id_unidade_medida'
										component={Select}
										label='Unidade de Medida' placeholder='Informe a unidade de medida'
										cols='12 12 12 12'
										readOnly={readOnly}
										options={this.props.listaUnidadeMedida} />

								</Row>
							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='6 6 4 3'>
										{this.props.excluir ? (
											<Button
												text='Excluir'
												submit type={'danger'}
												icon={'fa fa-trash'} />
										) : (
										<Button
											text='Salvar'
											submit
											disabled={this.props.colaborador || this.props.area}
											type={'success'}
											icon={'fa fa-check'} />
										)}
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</Form>
					</ContentCard>
				)}
			</>
        )
    }


}

SafraForm = reduxForm({form: 'safraForm', destroyOnUnmount: false})(SafraForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('safraForm')(state),
	registro: state.safra.registro,
	listaEspecie: state.safra.listaEspecie,
	listaProdutor: state.safra.listaProdutor,
	listaArea: state.safra.listaArea,
	listaUnidadeMedida: state.safra.listaUnidadeMedida
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SafraForm);
