import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
	return {
		type: 'ANALISE_SOLO_MODO_TELA',
		payload: {
			modo: modo,
			registro: {
				...registro,
				data_coleta: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_coleta)),
				data_recebimento: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_recebimento)),
				data_expedicao: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_expedicao))
			}
		}
	};
}
export function initForm(registro = {}) {
	return [
		initialize('analiseSoloForm', {
			...registro,
			data_coleta: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_coleta)),
			data_recebimento: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_recebimento)),
			data_expedicao: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_expedicao))
		})
	];
}

export function setAguardando(aguardando) {
	return {
		type: 'ANALISE_SOLO_SET_AGUARDANDO',
		payload: aguardando
	};
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/analiseSolo`);
	return {
		type: 'ANALISE_SOLO_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {
		if (!registro.id) {
			console.log(registro)
			axios.post(`${consts.API_URL}/analiseSolo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/analiseSolo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
	};
}

export function excluir(registro) {
	return dispatch => {
		axios.delete(`${consts.API_URL}/analiseSolo?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	};
}

export function getListaProdutor() {
	const request = axios.get(`${consts.API_URL}/produtor/listarSelect`);
	return {
		type: 'ANALISE_SOLO_PRODUTOR_SELECT_LISTADO',
		payload: request
	};
}

export function getListaArea() {
	const request = axios.get(`${consts.API_URL}/area/listarSelect`);
	return {
		type: 'ANALISE_SOLO_AREA_SELECT_LISTADO',
		payload: request
	};
}
