const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaTipo: [],
	listaTipoProduto: [],
	listaProduto: [],
	listaDoenca: [],
	listaPraga: [],
	listaPlantaInvasora: [],
	listaUnidadeMedida: [],
	listaFormaAplicacao: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'SAFRA_MANEJO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'SAFRA_MANEJO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'SAFRA_MANEJO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'SAFRA_MANEJO_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};
	
			case 'SAFRA_MANEJO_TIPO_PRODUTO_SELECT_LISTADO':
				return {
					...state,
					listaTipoProduto: action.payload.data
				};

		case 'SAFRA_MANEJO_PRODUTO_SELECT_LISTADO':
			return {
				...state,
				listaProduto: action.payload.data
			};

		case 'SAFRA_MANEJO_DOENCA_SELECT_LISTADO':
			return {
				...state,
				listaDoenca: action.payload.data
			};

		case 'SAFRA_MANEJO_PRAGA_SELECT_LISTADO':
			return {
				...state,
				listaPraga: action.payload.data
			};

		case 'SAFRA_MANEJO_PLANTA_INVASORA_SELECT_LISTADO':
			return {
				...state,
				listaPlantaInvasora: action.payload.data
			};

		case 'SAFRA_MANEJO_UNIDADE_MEDIDA_SELECT_LISTADO':
			return {
				...state,
				listaUnidadeMedida: action.payload.data
			};

		case 'SAFRA_MANEJO_FORMA_APLICACAO_SELECT_LISTADO':
			return {
				...state,
				listaFormaAplicacao: action.payload.data
			};
	
        default:
            return state;
    }
}
