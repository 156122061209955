import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import EspecieReducer from '../especie/especieReducer';
import PlantaInvasoraReducer from '../plantaInvasora/plantaInvasoraReducer';
import PragaReducer from '../praga/pragaReducer';
import DoencaReducer from '../doenca/doencaReducer';
import ProdutoTipoReducer from '../produtoTipo/produtoTipoReducer';
import ProdutoReducer from '../produto/produtoReducer';
import PessoaReducer from '../pessoa/pessoaReducer';
import ColaboradorReducer from '../pessoa/colaborador/colaboradorReducer';
import AreaReducer from '../pessoa/area/areaReducer';
import GalpaoReducer from '../pessoa/galpao/galpaoReducer';
import EquipamentoReducer from '../pessoa/equipamento/equipamentoReducer';
import SafraReducer from '../safra/safraReducer';
import SafraManejoReducer from '../safra/safraManejo/safraManejoReducer';
import SafraManejoProdutoReducer from '../safra/safraManejo/safraManejoProduto/safraManejoProdutoReducer';
import SafraManejoInvasorReducer from '../safra/safraManejo/safraManejoInvasor/safraManejoInvasorReducer';
import UnidadeMedidaReducer from '../unidadeMedida/unidadeMedidaReducer';
import SafraManejoTipoReducer from '../SafraManejoTipo/safraManejoTipoReducer';
import AnaliseSoloReducer from '../analiseSolo/analiseSoloReducer'
import HistoricoAcessoReducer from '../historicoAcesso/historicoAcessoReducer';
import ManejoRelatorioReducer from '../manejoRelatorio/manejoRelatorioReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	especie: EspecieReducer,
	plantaInvasora: PlantaInvasoraReducer,
	praga: PragaReducer,
	doenca: DoencaReducer,
	unidadeMedida : UnidadeMedidaReducer,
	safraManejoTipo : SafraManejoTipoReducer,
	produtoTipo: ProdutoTipoReducer,
	produto: ProdutoReducer,
	pessoa: PessoaReducer,
	colaborador: ColaboradorReducer,
	area: AreaReducer,
	galpao: GalpaoReducer,
	equipamento: EquipamentoReducer,
	safra: SafraReducer,
	safraManejo: SafraManejoReducer,
	safraManejoProduto: SafraManejoProdutoReducer,
	safraManejoInvasor: SafraManejoInvasorReducer,
	analiseSolo: AnaliseSoloReducer,
	historicoAcesso: HistoricoAcessoReducer,
	manejoRelatorio: ManejoRelatorioReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
