import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'HISTORICO_ACESSO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('historicoAcessoForm', {
			...registro
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'HISTORICO_ACESSO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/historicoAcesso`);
    return {
        type: 'HISTORICO_ACESSO_LISTADO',
        payload: request
    };
}

export function getListaProdutor() {
	const request = axios.get(`${consts.API_URL}/produtor/listarSelect`);
	return {
		type: 'HISTORICO_ACESSO_PRODUTOR_SELECT_LISTADO',
		payload: request
	};
}