const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
    listaDoencas: [{
		id: false,
		valor: 'Não'
	}, {
		id: true,
		valor: 'Sim'
	}],
    listaPragas: [{
		id: false,
		valor: 'Não'
	}, {
		id: true,
		valor: 'Sim'
	}],
    listaPlantaInvasora: [{
		id: false,
		valor: 'Não'
	}, {
		id: true,
		valor: 'Sim'
	}],
	listaProdutoTipo: []
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'SAFRA_MANEJO_TIPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'SAFRA_MANEJO_TIPO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'SAFRA_MANEJO_TIPO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};
		
		case 'SAFRA_MANEJO_TIPO_LISTADO_PRODUTO':
			return {
				...state,
				listaProdutoTipo: action.payload.data
			};

        default:
            return state;
    }
}
