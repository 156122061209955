import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../../common/template/content';
import ContentCard from '../../../common/template/contentCard';
import ContentCardHeader from '../../../common/template/contentCardHeader';
import ContentCardBody from '../../../common/template/contentCardBody';
import ContentCardFooter from '../../../common/template/contentCardFooter';
import Row from '../../../common/layout/row';
import Grid from '../../../common/layout/grid';
import Button from '../../../common/button/button';
import Form from '../../../common/form/form';
import LabelAndInput from '../../../common/form/labelAndInput';
import LabelAndInputNumber from '../../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../../common/form/labelAndInputDateTime';
import LabelAndInputDate from '../../../common/form/labelAndInputDate';
import Select from '../../../common/form/select';
import SelectAlteracao from '../../../common/form/selectAlteracao';
import { setError } from '../../../common/toast/toast';
import FormatUtils from '../../../common/formatUtils/FormatUtils';
import DateFormat from '../../../common/dateFormat/DateFormat';

import {
	setModoTela, initForm
} from './safraManejoInvasorActions';

class SafraManejoInvasorForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						{this.props.registro.doenca ? (
							<Field
								name='id_doenca'
								component={Select}
								label='Doença' placeholder='Informe a doença'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaDoenca} />
						) : null}

						{this.props.registro.praga ? (
							<Field
								name='id_praga'
								component={Select}
								label='Inseto/Praga' placeholder='Informe o inseto/praga'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaPraga} />
						) : null}

						{this.props.registro.planta_invasora ? (
							<Field
								name='id_planta_invasora'
								component={Select}
								label='Planta Invasora' placeholder='Informe a planta invasora'
								cols='12 12 12 12'
								readOnly={readOnly}
								options={this.props.listaPlantaInvasora} />
						) : null}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

SafraManejoInvasorForm = reduxForm({form: 'safraManejoInvasorForm', destroyOnUnmount: false})(SafraManejoInvasorForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('safraManejoInvasorForm')(state),
	registro: state.safraManejoInvasor.registro,
	listaTipoProduto: state.safraManejo.listaTipoProduto,
	listaProduto: state.safraManejo.listaProduto,
	listaDoenca: state.safraManejo.listaDoenca,
	listaPraga: state.safraManejo.listaPraga,
	listaPlantaInvasora: state.safraManejo.listaPlantaInvasora
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SafraManejoInvasorForm);
