const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaProdutor: [],
	listaArea: [],
	listaPeriodo: [{ id: 1, valor: 'Anual' }, { id: 2, valor: 'Bianual' }, { id: 3, valor: 'Trianual' }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ANALISE_SOLO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'ANALISE_SOLO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'ANALISE_SOLO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'ANALISE_SOLO_PRODUTOR_SELECT_LISTADO':
			return {
				...state,
				listaProdutor: action.payload.data
			};

		case 'ANALISE_SOLO_AREA_SELECT_LISTADO':
			return {
				...state,
				listaArea: action.payload.data
			};



        default:
            return state;
    }
}
