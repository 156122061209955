import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'AREA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				area: FormatUtils.formatarValorTela(registro.area, 2)
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('areaForm', {
			...registro,
			area: FormatUtils.formatarValorTela(registro.area, 2)
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'AREA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_produtor) {
    const request = axios.get(`${consts.API_URL}/area?id_produtor=${id_produtor}`);
    return {
        type: 'AREA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/area`, {
				...registro,
				telefone: FormatUtils.removerMascara(registro.telefone),
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_produtor));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/area`, {
				...registro,
				telefone: FormatUtils.removerMascara(registro.telefone),
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_produtor));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/area?id=${registro.id}${registro.permanente ? '&permanente=true' : ''}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_produtor));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarSelect`);
    return {
        type: 'AREA_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'AREA_CIDADE_SELECT_LISTADO',
        payload: request
    };
}
