import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import LabelAndInputDate from '../../common/form/labelAndInputDate';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import { setError } from '../../common/toast/toast';
import FormatUtils from '../../common/formatUtils/FormatUtils';
import DateFormat from '../../common/dateFormat/DateFormat';

import SafraManejoProduto from './safraManejoProduto/safraManejoProduto';
import SafraManejoInvasor from './safraManejoInvasor/safraManejoInvasor';

import {
	setModoTela, initForm,
} from './safraManejoActions';

class SafraManejoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Select
									options={this.props.listaTipo}
									label='Manejo'
									cols='12 12 12 12'
									placeholder='Selecione o tipo'
									readOnly={readOnly}
									multiple={true}
									value={this.props.listaTipo.map(item => ({ value: item.id, label: item.valor })).filter(item => (this.props.formularioValues.tipos || []).includes(item.value))}
									onChange={value => {
										this.props.initForm({
											...this.props.formularioValues,
											tipos: value.map(item => item.value)
										});
									}} />
								{(this.props.formularioValues.tipos || []).includes(13) ?
									<Field
										name='descricao'
										component={LabelAndInput}
										label='Cultivar' placeholder='Informe a cultivar'
										cols='12 12 12 12'
										readOnly={readOnly} />
									:
									<Field
										name='descricao'
										component={LabelAndInput}
										label='Descrição' placeholder='Informe a descrição'
										cols='12 12 12 12'
										readOnly={readOnly} />
								}
								<LabelAndInputDate
									name='data_aplicacao'
									label='Data da Aplicação' placeholder='Informe o data'
									cols='12 4 4 4'
									readOnly={readOnly}
									max={DateFormat.getDataAtual().split("/").join("-")}
									value={this.props.formularioValues.data_aplicacao}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											data_aplicacao: data.target.value
										});
									}} />

								<Field
									name='area'
									component={LabelAndInputNumber}
									label='Área (hectares)' placeholder='Informe a área'
									cols='12 6 4 4'
									casas={2}
									readOnly={readOnly} />

								{(this.props.formularioValues.tipos || []).includes(13) ?
									<>


										<Field
											name='densidade'
											component={LabelAndInputNumber}
											label='Densidade de Semeadura' placeholder='Informe a densidade'
											cols='12 6 4 4'
											casas={0}
											readOnly={readOnly} />

										<Field
											name='populacao'
											component={LabelAndInputNumber}
											label='População' placeholder='Informe a população'
											cols='12 6 4 4'
											casas={0}
											readOnly={readOnly} />

										<Field
											name='id_unidade_medida_populacao'
											component={Select}
											label='Unidade de Medida da População' placeholder='Informe a unidade de medida'
											cols='12 12 12 12'
											readOnly={readOnly}
											options={this.props.listaUnidadeMedida} />

										<Field
											name='espacamento'
											component={LabelAndInputNumber}
											label='Espaçamento entre Linhas (cm)' placeholder='Informe o espaçamento'
											cols='12 6 4 4'
											casas={0}
											readOnly={readOnly} />
									</>
									: null}

							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ? <SafraManejoProduto /> : null}

				{this.props.formularioValues.id ? <SafraManejoInvasor /> : null}

			</>
		)
	}


}

SafraManejoForm = reduxForm({ form: 'safraManejoForm', destroyOnUnmount: false })(SafraManejoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('safraManejoForm')(state),
	registro: state.safraManejo.registro,
	listaTipo: state.safraManejo.listaTipo,
	listaTipoProduto: state.safraManejo.listaTipoProduto,
	listaProduto: state.safraManejo.listaProduto,
	listaDoenca: state.safraManejo.listaDoenca,
	listaPraga: state.safraManejo.listaPraga,
	listaPlantaInvasora: state.safraManejo.listaPlantaInvasora,
	listaUnidadeMedida: state.safraManejo.listaUnidadeMedida
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SafraManejoForm);
