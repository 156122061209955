import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PRODUTO_TIPO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('produtoTipoForm', {
			...registro
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'PRODUTO_TIPO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/produtoTipo`);
    return {
        type: 'PRODUTO_TIPO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/produtoTipo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/produtoTipo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/produtoTipo?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
