const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaEspecie: [],
	listaColaboradorRegime:[],
	listaCapacidade: [{
		id: true,
		valor: 'Sim'
	}, {
		id: false,
		valor: 'Não'
	}],
	listaEpi:[{
		id: true,
		valor: 'Sim'
	}, {
		id: false,
		valor: 'Não'
	}],
	listaNr: [{
		id: true,
		valor: 'Sim'
	}, {
		id: false,
		valor: 'Não'
	}],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'COLABORADOR_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'COLABORADOR_LISTADO':
			return {
				...state,
				lista: action.payload.data
			};

		case 'COLABORADOR_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'COLABORADOR_ESPECIE_SELECT_LISTADO':
			return {
				...state,
				listaEspecie: action.payload.data
			};

		case 'COLABORADOR_CAPACIDADE_SELECT_LISTADO':
			return {
				...state,
				listaCapacidade: action.payload.data
			};

		case 'COLABORADOR_EPI_SELECT_LISTADO':
			return {
				...state,
				listaEpi: action.payload.data
			};
		case 'COLABORADOR_NR_SELECT_LISTADO':
			return {
				...state,
				listaNr: action.payload.data
			};
		
		case 'COLABORADOR_REGIME_SELECT_LISTADO':
			return {
				...state,
				listaColaboradorRegime: action.payload.data
			};

				default:
			return state;
			}
	}
