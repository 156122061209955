const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaProdutor: [],
	listaArea: [],
	listaEspecie: [],
	listaUnidadeMedida: [{  id: 15, valor: 'Quilograma (kg)' }, { id: 17, valor: 'Tonelada (Ton)' }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'SAFRA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'SAFRA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'SAFRA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'SAFRA_PRODUTOR_SELECT_LISTADO':
			return {
				...state,
				listaProdutor: action.payload.data
			};

		case 'SAFRA_AREA_SELECT_LISTADO':
			return {
				...state,
				listaArea: action.payload.data
			};

		case 'SAFRA_ESPECIE_SELECT_LISTADO':
			return {
				...state,
				listaEspecie: action.payload.data
			};

        default:
            return state;
    }
}
